// ////////////////////////////スマホ/タブレット判別
// var _ua = (function (u) {
//   return {
//     Tablet:
//       (u.indexOf('windows') != -1 && u.indexOf('touch') != -1) ||
//       u.indexOf('ipad') != -1 ||
//       (u.indexOf('android') != -1 && u.indexOf('mobile') == -1) ||
//       (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1) ||
//       u.indexOf('kindle') != -1 ||
//       u.indexOf('silk') != -1 ||
//       u.indexOf('playbook') != -1,
//     Mobile:
//       (u.indexOf('windows') != -1 && u.indexOf('phone') != -1) ||
//       u.indexOf('iphone') != -1 ||
//       u.indexOf('ipod') != -1 ||
//       (u.indexOf('android') != -1 && u.indexOf('mobile') != -1) ||
//       (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1) ||
//       u.indexOf('blackberry') != -1,
//   };
// })(window.navigator.userAgent.toLowerCase());
// // if(_ua.Mobile){}

// var osVer;
// osVer = 'Android';

// var ua = navigator.userAgent.toLowerCase();
// var iPadFlag = false;
// if (/android|ipod|ipad|iphone|macintosh/.test(ua) && 'ontouchend' in document) {
//   iPadFlag = true;
// }
// // if (navigator.userAgent.indexOf(osVer)>0){
// // }

// var breakNum = 768;
// var tabletNum = 1024;
// ////////////////////////////////////init
// $(function () {
//   if ($('#wrapper').hasClass('home')) {
//   }
//   // ///////////
//   if (!_ua.Mobile && !_ua.Tablet) {
//   }
//   // ///////////
// });

$(window).on('load', function () {
  setSubmit();
  // if (!_ua.Mobile) {
  // }
  // if (!_ua.Mobile && !_ua.Tablet) {
  // }
});

//Enterでsubmit発火
function setSubmit() {
  document.forms.ecForm.ecBtn.addEventListener('keyup', function (event) {
    if (event.keyCode === 13) {
      this.form.submit();
    }
  });
}
