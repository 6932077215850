breakPoint = 959;

/* openingKV
--------------------------------------------------*/
function openingKV() {
  /* main-copy */
  var mainmetacopy = new TimelineMax();
  mainmetacopy.call(
    function () {
      $('.l-main__meta .main-copy').addClass('active');
    },
    null,
    null,
    0.35
  );

  /* main-read */
  var mainmetaread = new TimelineMax();
  mainmetaread.call(
    function () {
      $('.l-main__meta .main-read').addClass('active');
    },
    null,
    null,
    0.8
  );

  /* main-img */
  var mainmetaimg = new TimelineMax();
  mainmetaimg.call(
    function () {
      $('.l-main__img .js-visual__anime').addClass('active');
    },
    null,
    null,
    0.15
  );

  /* main-en__copy */
  var mainencopy = new TimelineMax();
  mainencopy.call(
    function () {
      $('.main-en__copy').addClass('active');
    },
    null,
    null,
    1.2
  );

  /* main-bgimg */
  var mainmetabgimg = new TimelineMax();
  mainmetabgimg.call(
    function () {
      $('.l-main__bg.js-visual__anime').addClass('active');
    },
    null,
    null,
    0.6
  );
}

/* menu
--------------------------------------------------*/
function menu() {
  var scroll_top;
  $('#l-header__toggle, .modal-menu__unit .l-nav__lst li a,.modal-menu__guide-inner a').on(
    'click',
    function (e) {
      if (!$('.modal-menu__container').hasClass('menu-contents__open')) {
        scroll_top = $(window).scrollTop();
        $('.modal-menu__container').scrollTop(0);
        $('#l-header__toggle .toggle').addClass('active');
        $('.modal-menu__container,.l-header__body').addClass('menu-contents__open');
      } else {
        $('.modal-menu__container,.l-header__body').removeClass('menu-contents__open');
        $('#l-header__toggle .toggle').removeClass('active');
      }
    }
  );
  $('#modal-menu__close,.modal-menu__unit .l-nav__lst li a').on('click', function () {
    $('.modal-menu__container,.l-header__body').removeClass('menu-contents__open');
  });

  /* toggle
--------------------------------------------------*/
  $('.toggle-icon').on('click', function () {
    $(this).next('.js-nav__child').slideToggle();
    $(this).toggleClass('open');
  });
}

/* about-gallery-slider
--------------------------------------------------*/
var aboutgalleryslider = function () {
  var mySwiper = new Swiper('#about-gallery-slider .swiper-container', {
    effect: 'slide',
    slidesPerView: 3.5,
    slidesPerGroup: 1,
    spaceBetween: 25,
    centeredSlides: true,
    disableOnInteraction: true,
    speed: 6000,
    dots: true,
    loop: true,
    nested: true,
    autoplay: {
      delay: 0,
    },
    breakpoints: {
      959: {
        slidesPerView: 2.5,
        slidesPerGroup: 1,
      },
      569: {
        slidesPerView: 2,
        slidesPerGroup: 1,
        spaceBetween: 10,
      },
    },
  });
};

/* howto-slider
--------------------------------------------------*/
var howtoslider = function () {
  var mySwiper = new Swiper('#howto-slider .swiper-container', {
    effect: 'slide',
    autoplay: false,
    slidesPerView: 4,
    slidesPerGroup: 1,
    spaceBetween: 25,
    loop: true,
    centeredSlides: true,
    disableOnInteraction: true,
    speed: 700,
    nested: true,
    breakpoints: {
      959: {
        slidesPerView: 3.5,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
      569: {
        slidesPerView: 2,
        slidesPerGroup: 1,
        spaceBetween: 15,
      },
    },

    navigation: {
      nextEl: '#howto-slider .swiper-button-next',
      prevEl: '#howto-slider .swiper-button-prev',
    },
    pagination: {
      el: '#howto-slider .swiper-pagination',
      clickable: true,
    },
  });
};
/* howto-slider mousetip
--------------------------------------------------*/
var howtomousetip = function () {
  var $slideUnit = $('#howto-slider .swiper-container .swiper-wrapper'),
    $prev = $slideUnit.find('#howto-slider .swiper-common.mousetip .swiper-button-prev'),
    $next = $slideUnit.find('#howto-slider .swiper-common.mousetip .swiper-button-next');

  $(document).on(
    {
      mousemove: function (e) {
        var mouseX = e.clientX - $(this).offset().left,
          mouseY = $(window).scrollTop() - $slideUnit.offset().top + e.clientY;
        $(this).find('.mouse-arrow').css({
          top: mouseY,
          left: mouseX,
        });
      },
    },
    '#howto-slider .swiper-common.mousetip .swiper-button-prev'
  );

  $(document).on(
    {
      mousemove: function (e) {
        var mouseX = e.clientX - $(this).offset().left,
          mouseY = $(window).scrollTop() - $slideUnit.offset().top + e.clientY;
        $(this).find('.mouse-arrow').css({
          top: mouseY,
          left: mouseX,
        });
      },
    },
    '#howto-slider .swiper-common.mousetip .swiper-button-next'
  );
};

/* comment-slider
--------------------------------------------------*/
var commentslider = function () {
  var mySwiper = new Swiper('#comment-slider .swiper-container', {
    effect: 'slide',
    autoplay: false,
    slidesPerView: 3.5,
    slidesPerGroup: 1,
    spaceBetween: 30,
    loop: true,
    centeredSlides: true,
    disableOnInteraction: true,
    speed: 700,
    nested: true,
    breakpoints: {
      959: {
        slidesPerView: 2,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
      569: {
        slidesPerView: 1.3,
        slidesPerGroup: 1,
        spaceBetween: 15,
      },
    },

    navigation: {
      nextEl: '#comment-slider .swiper-button-next',
      prevEl: '#comment-slider .swiper-button-prev',
    },
    pagination: {
      el: '#comment-slider .swiper-pagination',
      clickable: true,
    },
  });
};
/* comment-slider mousetip
--------------------------------------------------*/
var commentmousetip = function () {
  var $slideUnit = $('#comment-slider .swiper-container .swiper-wrapper'),
    $prev = $slideUnit.find('#comment-slider .swiper-common.mousetip .swiper-button-prev'),
    $next = $slideUnit.find('#comment-slider .swiper-common.mousetip .swiper-button-next');

  $(document).on(
    {
      mousemove: function (e) {
        var mouseX = e.clientX - $(this).offset().left,
          mouseY = $(window).scrollTop() - $slideUnit.offset().top + e.clientY;
        $(this).find('.mouse-arrow').css({
          top: mouseY,
          left: mouseX,
        });
      },
    },
    '#comment-slider .swiper-common.mousetip .swiper-button-prev'
  );

  $(document).on(
    {
      mousemove: function (e) {
        var mouseX = e.clientX - $(this).offset().left,
          mouseY = $(window).scrollTop() - $slideUnit.offset().top + e.clientY;
        $(this).find('.mouse-arrow').css({
          top: mouseY,
          left: mouseX,
        });
      },
    },
    '#comment-slider .swiper-common.mousetip .swiper-button-next'
  );
};

/* fixedcommon
--------------------------------------------------*/
function fixedcommon() {
  window.onscroll = function () {
    scrollToggleClass('.l-content', '.fixed-common__wrap', 'active');
  };
  function scrollToggleClass(rangeTarget, addTarget, classname) {
    if ($(rangeTarget).length) {
      scroll = $(window).scrollTop();
      startPos = $(rangeTarget).offset().top;
      endPos = startPos + $(rangeTarget).outerHeight();
      if (scroll > startPos && scroll < endPos) {
        $(addTarget).addClass(classname);
      } else {
        $(addTarget).removeClass(classname);
      }
    }
  }
}

/* item-slider01
--------------------------------------------------*/
var itemslider01 = function () {
  var mySwiper = new Swiper('#item-slider01 .swiper-container', {
    effect: 'slide',
    autoplay: false,
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
    loop: true,
    centeredSlides: true,
    disableOnInteraction: true,
    speed: 700,
    nested: true,
    breakpoints: {
      959: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      569: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },

    navigation: {
      nextEl: '#item-slider01 .swiper-button-next',
      prevEl: '#item-slider01 .swiper-button-prev',
    },
    pagination: {
      el: '#item-slider01 .swiper-pagination',
      clickable: true,
    },
  });
};

/* item-slider02
--------------------------------------------------*/
var itemslider02 = function () {
  var mySwiper = new Swiper('#item-slider02 .swiper-container', {
    effect: 'slide',
    autoplay: false,
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
    loop: true,
    centeredSlides: true,
    disableOnInteraction: true,
    speed: 700,
    nested: true,
    breakpoints: {
      959: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      569: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },

    navigation: {
      nextEl: '#item-slider02 .swiper-button-next',
      prevEl: '#item-slider02 .swiper-button-prev',
    },
    pagination: {
      el: '#item-slider02 .swiper-pagination',
      clickable: true,
    },
  });
};

/* item-slider03
--------------------------------------------------*/
var itemslider03 = function () {
  var mySwiper = new Swiper('#item-slider03 .swiper-container', {
    effect: 'slide',
    autoplay: false,
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
    loop: true,
    centeredSlides: true,
    disableOnInteraction: true,
    speed: 700,
    nested: true,
    breakpoints: {
      959: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      569: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },

    navigation: {
      nextEl: '#item-slider03 .swiper-button-next',
      prevEl: '#item-slider03 .swiper-button-prev',
    },
    pagination: {
      el: '#item-slider03 .swiper-pagination',
      clickable: true,
    },
  });
};

/* fixedcommon
--------------------------------------------------*/
function fixedcommon() {
  window.onscroll = function () {
    scrollToggleClass('.l-content', '.fixed-common__wrap', 'active');
  };
  function scrollToggleClass(rangeTarget, addTarget, classname) {
    if ($(rangeTarget).length) {
      scroll = $(window).scrollTop();
      startPos = $(rangeTarget).offset().top;
      endPos = startPos + $(rangeTarget).outerHeight();
      if (scroll > startPos && scroll < endPos) {
        $(addTarget).addClass(classname);
      } else {
        $(addTarget).removeClass(classname);
      }
    }
  }
}

/* spansplit
--------------------------------------------------*/
function spansplit() {
  var setElm = $('.js-split');
  setElm
    .children()
    .addBack()
    .contents()
    .each(function () {
      var elmThis = $(this);
      if (this.nodeType == 3) {
        var $this = $(this);
        $this.replaceWith($this.text().replace(/(\S)/g, '<span>$&</span>'));
      }
    });
}

/* scroolanime
--------------------------------------------------*/
function scroolanime() {
  $(window).scroll(function () {
    $('.js-scroll__anime, .js-scroll__split, .js-scroll__label').each(function () {
      var elemPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > elemPos - windowHeight + 100) {
        $(this).addClass('active');
      }
    });

    //スマホ閲覧時有効
    if (navigator.userAgent.match(/(iPhone|iPod|Android)/)) {
      $('.js-scroll__anime-sp').each(function () {
        var elemPos = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > elemPos - windowHeight + 100) {
          $(this).addClass('active');
        }
      });
    } //スマホ閲覧時有効
  });
  $(window).scroll();
}

/* acdion-toggle
--------------------------------------------------*/
function acdiontoggle() {
  $('.acdion-toggle').on('click', function () {
    $(this).next('.js-nav__child').slideToggle();
    $(this).toggleClass('open');
  });
}

/* scrollAnker
--------------------------------------------------*/

function scrollAnker() {
  $('a[href^="#"]').click(function (e) {
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    var position = target.offset().top;

    $.when(
      $('html, body').animate(
        {
          scrollTop: position,
        },
        400,
        'swing'
      ),
      e.preventDefault()
    ).done(function () {
      var diff = target.offset().top;
      if (diff === position) {
      } else {
        $('html, body').animate(
          {
            scrollTop: diff,
          },
          10,
          'swing'
        );
      }
    });
  });
}

/* int
--------------------------------------------------*/
function int() {
  $(window).on('load', function () {
    var mobileFlag = false;
    var wWidth = $(window).width();
    if (wWidth < breakPoint) {
      mobileFlag = true;
    } else {
      mobileFlag = false;
    }
    var timer = false;
    $(window).resize(function () {
      if (timer !== false) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        var wWidth = $(window).width();
        if (wWidth > breakPoint && mobileFlag) {
          mobileFlag = false;
        } else if (wWidth < breakPoint && !mobileFlag) {
          mobileFlag = true;
        }
      }, 200);
    }); //resize
  });
}

/* hover
--------------------------------------------------*/
function hover() {
  $(window).on('load resize orientationchange', function () {
    if ($(window).width() <= 959) {
      $('body').removeClass('hover');
    } else {
      $('body').addClass('hover');
    }
  });
}

/* fixedcommon
--------------------------------------------------*/
function fixedcommon() {
  window.onscroll = function () {
    scrollToggleClass('.l-content__inner', '.fixed-btn', 'active');
  };
  function scrollToggleClass(rangeTarget, addTarget, classname) {
    if ($(rangeTarget).length) {
      scroll = $(window).scrollTop();
      startPos = $(rangeTarget).offset().top;
      endPos = startPos + $(rangeTarget).outerHeight();
      if (scroll > startPos && scroll < endPos) {
        $(addTarget).addClass(classname);
      } else {
        $(addTarget).removeClass(classname);
      }
    }
  }
}

/* function
--------------------------------------------------*/
$(function () {
  openingKV();
  menu();
  aboutgalleryslider();
  howtoslider();
  howtomousetip();
  commentslider();
  commentmousetip();
  itemslider01();
  itemslider02();
  itemslider03();
  fixedcommon();
  spansplit();
  scroolanime();
  acdiontoggle();
  scrollAnker();
  int();
  hover();
  fixedcommon();
});
